<template>
  <div class="elv-base-cell-text-icon" :class="props.align ? `elv-base-cell-text-icon-${props.align}` : ''">
    <div class="elv-base-cell-text-icon-text" :style="{ fontWeight: props.weight || 400 }" :title="titleDesc">
      {{ props.text }}
    </div>
    <el-popover
      v-if="props.hoverShowPop"
      :show-arrow="false"
      :width="'auto'"
      placement="top-start"
      :enterable="false"
      :show-after="500"
      trigger="hover"
    >
      <template #reference>
        <img class="elv-base-cell-text-icon-img" :src="props.icon" />
      </template>
      <slot></slot>
    </el-popover>
    <img v-else-if="props.icon" class="elv-base-cell-text-icon-img" :src="props.icon" />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  align?: string
  text: string
  titleDesc?: string
  icon: string
  hoverShowPop: boolean
  weight?: number
}>()
</script>
<style lang="scss">
.elv-base-cell-text-icon {
  color: #0e0f11;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-left {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-right {
    justify-content: flex-end;
  }

  &-text {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 4px;
  }

  &-img {
    width: 14px;
    height: 14px;
    display: block;
    border-radius: 50%;
  }
}
</style>
