<template>
  <img :src="providerIcon" />
</template>
<script lang="ts" setup>
import coinGeckoIcon from '@/assets/img/project/coinGeckoIcon.png'
import defaultPriceIcon from '@/assets/icons/defaultPriceIcon.svg'
import exchangeRateIcon from '@/assets/img/project/exchangeRateIcon.png'

const props: any = defineProps<{
  type: string
}>()

const providerIcon = computed(() => {
  switch (props.type) {
    case 'COINGECKO':
    case 'API_COINGECKO':
      return coinGeckoIcon
    case 'EXCHANGERATE':
    case 'API_EXCHANGE_RATE':
      return exchangeRateIcon
    default:
      return defaultPriceIcon
  }
})
</script>
<style lang=""></style>
