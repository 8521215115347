<template>
  <div class="elv-base-period">
    <div class="elv-base-period__label">{{ t('common.period') }}</div>
    <div ref="dateTimeRef" v-click-outside="onCloseDateTime" @click="onOpenDateTime">
      <div class="elv-base-period__value">
        <span v-if="dateTimeValue[0] && dateTimeValue[1]"
          >{{ dayjs(dateTimeValue[0])?.format('YYYY/MM/DD') }} -
          {{ dayjs(dateTimeValue[1]).format('YYYY/MM/DD') }}</span
        >
      </div>
      <SvgIcon name="arrow-down-line" width="16" height="16" :style="{ transform: rotate }" />
    </div>
  </div>
  <div v-show="false">
    <el-date-picker
      ref="popoverRef"
      v-model="dateTimeValue"
      popper-class="elv-base-dateTime-popper"
      :virtual-ref="dateTimeRef"
      virtual-triggering
      type="datetimerange"
      value-format="YYYY-MM-DD"
      :clearable="false"
      :shortcuts="shortcuts"
      range-separator="To"
      :disabled-date="disabledDate"
      :default-value="defaultTime"
      start-placeholder="Start date"
      end-placeholder="End date"
      @change="onChangeTime"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'
import { ClickOutside as vClickOutside } from 'element-plus'

const props = defineProps({
  defaultTime: {
    type: Array,
    default: () => ['', '']
  }
})

const emit = defineEmits(['onChangeTime'])

dayjs.extend(utc)
dayjs.extend(timezone)

const { t } = useI18n()
const entityStore = useEntityStore()

const popoverRef = ref()
const dateTimeRef = ref()
const dateTimeValue: any = ref(['', ''])
const rotate = ref('rotate(0deg)')

/**
 * @description: 默认开始时间
 * @return {string}
 */
const defaultStartTime = () => {
  const timezoneData = entityStore.entityDetail?.timezone ?? 'UTC'
  return entityStore.entityDetail?.creationDate
    ? dayjs.tz(entityStore.entityDetail?.creationDate, timezoneData).format('YYYY-MM-DD')
    : dayjs.tz(entityStore.entityDetail?.createdAt, timezoneData).subtract(1, 'day').format('YYYY-MM-DD')
}

/**
 * @description: 默认结束时间
 * @return {string}
 */
const defaultEndTime = () => {
  return dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD')
}

// 快捷选项
const shortcuts = [
  {
    text: 'All Time',
    value: () => {
      return [defaultStartTime(), defaultEndTime()]
    }
  },
  {
    text: 'Last 7d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: 'Last 30d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  }
]

/**
 * @description: 禁用时间
 * @param {Date} time
 * @return {boolean}
 */
const disabledDate = (time: Date) => {
  return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
}

/**
 * @description: 打开时间选择器
 */
const onOpenDateTime = () => {
  rotate.value = rotate.value === 'rotate(0deg)' ? 'rotate(-180deg)' : 'rotate(0deg)'
  if (rotate.value === 'rotate(0deg)') {
    unref(popoverRef)?.handleClose?.()
  } else {
    unref(popoverRef)?.handleOpen?.()
  }
}

/**
 * @description: 关闭时间选择器
 */
const onCloseDateTime = () => {
  rotate.value = 'rotate(0deg)'
  unref(popoverRef)?.handleClose?.()
}

/**
 * @description: 时间选择器改变
 */
const onChangeTime = () => {
  rotate.value = 'rotate(0deg)'
  emit('onChangeTime', dateTimeValue.value)
}

defineExpose({ dateTimeValue })

watch(
  () => props.defaultTime,
  () => {
    if (props.defaultTime.length && props.defaultTime[0] && props.defaultTime[1]) {
      dateTimeValue.value = props.defaultTime
    } else {
      dateTimeValue.value = [defaultStartTime(), defaultEndTime()]
    }
  },
  { immediate: true }
)
</script>
<style lang="scss">
.elv-base-period {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 16px;

  &.is-disabled .elv-base-period__label + div {
    cursor: not-allowed;
  }

  .elv-base-period__label {
    color: #838d95;
    margin-right: 8px;

    + div {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  .elv-base-period__value {
    color: #1e2024;
    margin-right: 4px;
  }

  &:not(.is-disabled):hover {
    .elv-base-period__icon {
      fill: #5e85eb;
    }
  }
}

.elv-base-dateTime-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-range-picker {
    &.has-sidebar {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-base-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
